import axios from 'axios'
import { history } from './history'
//import ReactDOM from 'react-dom/client';
//import { GetBaseHeaders } from './sign'

const http = axios.create({
  baseURL: 'https://www.asnks.cn/index.php/home',
  // baseURL: '/index.php/home',
  // baseURL: 'http://localhost:8081/index.php/home',
  timeout: 5000,
})

//let requestCount = 0;

/* function showLoading() {
  if (requestCount === 0) {
    var dom = document.createElement('div');
    dom.setAttribute('id', 'loading');
    document.body.appendChild(dom);
    ReactDOM.render(dom);
  }
  requestCount++
}

function hideLoading() {
  requestCount--
  if (requestCount === 0) {
    document.body.removeChild(document.getElementById('loading'));
  }
} */

// 添加请求拦截器
http.interceptors.request.use((config) => {
  // showLoading()
   config.headers['authorization']='Bearer '
   return config

}, (error) => {
 // hideLoading()
  return Promise.reject(error)
})

// 添加响应拦截器
http.interceptors.response.use(

  (response) => {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    // return response.data
    //hideLoading()
    return Promise.resolve(response.data)
  }, (error) => {
    //hideLoading()
    if (error.response) {
      // 如401我就让用户返回登录页
      if (error.response.status === 401) {
        history.push('/login')
      }

      if (error.response.status === 400) {
        history.push('/login')
      }

      // 比如返回报错你的页面可能会崩溃，你需要在它崩溃之前做一些操作的话，可以在这里
      return Promise.reject(error)
    } else {
      return Promise.reject('请求超时, 请刷新重试')
    }


  })

export { http }